$dark-grey: #202020;
$light-grey: rgba(0, 0, 0, 0.12);
$mid-gray: rgba(0, 0, 0, 0.7);
$nc-blue: #2F80ED;
$red: #FC3F00;

button {
  border: none;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: bold;
  padding: 0.75em; // 12px/16px
  display: inline-block;
  text-decoration: none;
  
  &:hover {
    cursor: pointer;
  }
  &:focus {
    text-decoration: none;
    outline: none;
  }
}
.comment-box {
  color: $dark-grey;
}
.comment-form {
  margin: 1rem auto;
  padding-bottom: 1rem;
  border-bottom: solid 1px $light-grey;
}
.reply-form {
  margin: 1rem auto;
  padding-bottom: 1rem;
}
.comment-form-fields {
  margin-bottom: 0.25rem;
  input, textarea {
    border: none;
    background-color: #f2f2f2;
    font-size: 0.85rem;
    padding: 0.5rem;
    width: 99%;
    
    &:focus {
      border-bottom-color: $nc-blue;
      outline: none;
    }
  }
  textarea {
    font-style: italic;
  }
}
.comment-form-actions {
  
}
.comment-count {
  color: $dark-grey;
}
#comment-submit {
  // float: right;
  background-color: $nc-blue;
  color: #fff;
}

.comment {
  border-top: 1px solid $light-grey;
}
.reply{
  margin-left: 30px;
  margin-top: 20px;
}

.reply-list {
  border-left: 1px solid $light-grey;
  margin-left: 20px
}

.comment-header {
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 14px;
}
.date {
  margin-bottom: 5px;
  margin-top: 5px;
  color: rgba(0, 0, 0, 0.6)
}
.comment-body {
  color: $mid-gray
}

.author-group {
  display: flex;
  gap: 13px;
  margin-top: 10px;
  align-items: center;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-position: center;
  background-size: cover;
}


